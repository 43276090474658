/** @jsx jsx */
import {Box, jsx,useThemeUI} from 'theme-ui'
import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'





export default function Katrin(){
  const colors=useThemeUI()
  const farbe=colors.colorMode==="dark"? "white":"black"
  
  
  const props=useSpring({ 
    from:{opacity:0,marginTop:250,color:'tomato'},
    to: async update => {
      await update({opacity:.7})
      await update({marginTop:20})
      await update({color:farbe})
     
     
    }
    ,config:config.gentle})

  return(
    <Layout>
      <MySEO title="Ihr Webspace" description="Sie können Webspace für Ihre Präsentation bei uns erhalten " />
      <animated.div style={props}>
        <Box  sx={{p:3}}>
          <h2 color="text">Hier gestalten wir Ihren Auftritt( Portrait, Text und Bilder ) </h2>
          </Box>
       
      </animated.div>
    </Layout>
  )
}

